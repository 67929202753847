export const SidebarHeading = [
  "EVENTS",
  "VENUES",
  "PACKAGE",
  "SPONSORS",
  "NOTIFICATION",
  "TEAM",
  "BANNERS",
  "FAQ",
  "CITIES",
  "TICKETS",
  "SIGN OUT",
  "JOBS",
  "CATEGORY",
];

export const SideHeading = [
  {
    name: "EVENTS",
    redirectPath: "",
    image: "",
  },
];
