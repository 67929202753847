import logo from "../../Assets/SideBar-Image/logo.png";
import events from "../../Assets/SideBar-Image/ic-events.png";
import venues from "../../Assets/SideBar-Image/ic-venues.png";
import packag from "../../Assets/SideBar-Image/ic-package.png";
import sponsors from "../../Assets/SideBar-Image/ic-sponsors.png";
import notifications from "../../Assets/SideBar-Image/ic-notifications.png";
import team from "../../Assets/SideBar-Image/ic-team.png";
import home from "../../Assets/SideBar-Image/ic-home.png";
import faq from "../../Assets/SideBar-Image/ic-faq.png";
import city from "../../Assets/SideBar-Image/ic-city.png";
import ticket from "../../Assets/SideBar-Image/ic-ticket.png";
import signout from "../../Assets/SideBar-Image/ic-signout.png";
import "./SideBar.Style.scss";
import { NavLink, useLocation } from "react-router-dom";

import { SidebarHeading } from "../../Store/Store";
import React from "react";
interface Iprops {
  setSigninStatus?: any;
}
const SideBar: React.FC<Iprops> = ({ setSigninStatus }) => {
  const { pathname } = useLocation();

  function hideNavbar() {
    const x: any = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  const signOut = () => {
    localStorage.clear();
    setSigninStatus();
  };

  return (
    <>
      <div className="mobile-container">
        <div className="topnav">
          <a href="#home" className="active-logo">
            <img className="logo-logo" src={logo} alt="" />
          </a>
          <div id="myLinks">
            <NavLink to="/" className="active" onClick={() => hideNavbar()}>
              <img src={events} className="icons" alt="" />
              <span className="item">{SidebarHeading[0]}</span>
            </NavLink>
            <NavLink
              to="/venues"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={venues} className="icons" alt="" />
              <span className="item">{SidebarHeading[1]}</span>
            </NavLink>
            <NavLink
              to="/package"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={packag} className="icons" alt="" />
              <span className="item">{SidebarHeading[2]}</span>
            </NavLink>
            <NavLink
              to="/sponsors"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={sponsors} className="icons" alt="" />
              <span className="item">{SidebarHeading[3]}</span>
            </NavLink>
            <NavLink
              to="/category"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={notifications} className="icons" alt="" />
              <span className="item">{SidebarHeading[12]}</span>
            </NavLink>
            <NavLink
              to="/Jobs"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={sponsors} className="icons" alt="" />
              <span className="item">{SidebarHeading[11]}</span>
            </NavLink>
            <NavLink
              to="/notifications"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={notifications} className="icons" alt="" />
              <span className="item">{SidebarHeading[4]}</span>
            </NavLink>

            <NavLink
              to="/team"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={team} className="icons" alt="" />
              <span className="item">{SidebarHeading[5]}</span>
            </NavLink>
            <NavLink
              to="/home"
              state={{ id: 1 }}
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={home} className="icons" alt="" />
              <span className="item">{SidebarHeading[6]}</span>
            </NavLink>
            <NavLink
              to="/faq"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={faq} className="icons" alt="" />
              <span className="item">{SidebarHeading[7]}</span>
            </NavLink>
            <NavLink
              to="/city"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={city} className="icons" alt="" />
              <span className="item">{SidebarHeading[8]} </span>
            </NavLink>
            <NavLink
              to="/ticket"
              className="MobileLinks"
              onClick={() => hideNavbar()}
            >
              <img src={ticket} className="icons" alt="" />
              <span className="item">{SidebarHeading[9]}</span>
            </NavLink>
            <NavLink
              to="/"
              className="MobileLinks"
              onClick={() => {
                hideNavbar();
                signOut();
              }}
            >
              <img src={signout} className="icons" alt="" />
              <span className="item">{SidebarHeading[10]}</span>
            </NavLink>
          </div>
          <a
            className="icon"
            onClick={() => {
              hideNavbar();
            }}
          >
            <i className="fa fa-bars" />
          </a>
        </div>
      </div>
      <div className="wrapper">
        <div className="sidebar MobileView">
          <div className="scrolbar ">
            <div className="logo-waterfront">
              <img className="logo-logo" src={logo} alt="" />
            </div>
            <ul className="sideListItem">
              <li>
                <NavLink
                  to="/events"
                  className={`${
                    ["/events", "/eventchild"].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={events} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[0]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/venues"
                  className={`${
                    [
                      "/venues",
                      `/venuesChild/${pathname.split("/")[2]}`,
                    ].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={venues} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[1]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/package"
                  className={`${
                    [
                      "/package",
                      `/packages-child/${pathname.split("/")[2]}`,
                    ].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={packag} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[2]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sponsors"
                  className={`${
                    [
                      "/sponsors",
                      "/SponserChild",
                      `/SponserChild/${pathname.split("/")[2]}`,
                    ].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={sponsors} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[3]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/category">
                  <img src={notifications} className="iconimg" alt="" />
                  <span className="item">Categories</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/jobs"
                  className={`${
                    [
                      `/jobs`,
                      `/jobsdetails`,
                      `/applicent/${pathname.split("/")[2]}`,
                      `/jobsdetails/${pathname.split("/")[2]}`,
                      `/jobsChild/${pathname.split("/")[2]}`,
                    ].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={sponsors} className="iconimg" alt="" />
                  <span className="item">JOBS</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/notifications">
                  <img src={notifications} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[4]}</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/team"
                  className={`${
                    ["/team", "/teamChild", "/AddNewTeam"].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={team} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[5]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/home?tab=1"
                  className={`${
                    [
                      "/home",
                      "/bannerChild",
                      `/bannerChild/${pathname.split("/")[2]}`,
                      "/adBannerChild",
                      `/adBannerChild/${pathname.split("/")[2]}`,
                      "/storeBannerChild",
                      `/storeBannerChild/${pathname.split("/")[2]}`,
                    ].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={home} className="iconimg" alt="" />
                  <span className="item">HOME BANNERS</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/faq"
                  className={`${
                    ["/faq", `/FAQChild/${pathname.split("/")[2]}`].includes(
                      pathname
                    )
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={faq} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[7]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/city"
                  className={`${
                    ["/city", "/citiesChild", "/AddNew"].includes(pathname)
                      ? `active`
                      : ``
                  }`}
                >
                  <img src={city} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[8]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/ticket">
                  <img src={ticket} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[9]}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/" onClick={signOut}>
                  <img src={signout} className="iconimg" alt="" />
                  <span className="item">{SidebarHeading[10]}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
